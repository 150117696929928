import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Button, Input, Spinner } from "reactstrap";
import { removeCarImage } from "../../../util/api";

const imageStyle = {
  height: "174px",
  width: "232px",
  objecFit: "contain",
};

const buttonStyle = {
  marginTop: "1rem",
  cursor: "pointer",
  height: "44px",
  border: "1px solid darkgray",
  borderRadius: "6px",
  background: "#0062cc",
  color: "white",
  fontSize: "14px",
  padding: "2%",
};

const UploadImages = (props) => {
  const images = props.carDetails && props.carDetails.images;
  const [newImages, setNewImages] = useState([]);
  const [file, setFile] = useState([]);

  useEffect(() => {}, [props.images, newImages, file]);

  const handleNewImages = (event) => {
    let images = [...newImages];
    let files = [...file];
    for (let i = 0; i < event.target.files.length; i++) {
      files.push(event.target.files[i]);
      images.push({ value: URL.createObjectURL(event.target.files[i]), name: event.target.files[i].name });
    }
    setNewImages(images);
    setFile(files);
    props.setFieldValue(props.name, files);
  };

  const deleteFromNewImages = (e, image) => {
    e.preventDefault();
    let images = [...newImages];
    let files = [...file];
    images = images.filter((img) => img !== image);
    files = files.filter((file) => {
      return file.name !== image.name;
    });
    toast.success(`🚀 image deleted successfully`);
    setNewImages(images);
    setFile(files);
    props.setFieldValue(props.name, files);
  };

  const deleteFromUploadedImages = (e, image) => {
    e.preventDefault();
    removeCarImage(props.carId, image)
      .then((res) => {
        if (res.status === "success") {
          toast.success(`🚀 ${res.message}`);
          props.fetchCarDetails();
        } else {
          toast.error(`⛔ ${res.message}`);
        }
      })
      .catch((err) => {
        toast.error("⛔ Failed to delete the image");
      });
  };

  if (images !== undefined) {
    return (
      <>
        <div className="row" style={{ padding: "1rem", border: "1px solid gray", borderRadius: "6px" }}>
          <p style={{ fontSize: "16px", width: "100%", padding: "1rem" }}>All Images</p>
          {images.map((image) => (
            <div className="col-lg-2 col-md-3 col-6">
              <img src={image.original} style={imageStyle}></img>
              <Button color="danger" onClick={(e) => deleteFromUploadedImages(e, image)}>
                Delete
              </Button>
            </div>
          ))}
          {newImages !== [] &&
            newImages.map((image) => (
              <div className="col-lg-2 col-md-3 col-6">
                <img src={image.value} style={imageStyle}></img>
                <Button color="danger" onClick={(e) => deleteFromNewImages(e, image)}>
                  Delete
                </Button>
              </div>
            ))}
          <div className="col-md-12">
            <div className="col-md-4">
              <p for="file" style={{ fontSize: "14px" }}>
                Upload Images
              </p>
              <Input id="file" name="file" type="file" accept=".jpg,.jpeg,.gif,.png" multiple onChange={(event) => handleNewImages(event)} style={{ display: "none" }} />
              <label for="file" style={buttonStyle}>
                Choose Files
              </label>
              <label style={{ marginLeft: "1rem", fontSize: "14px" }}>{file.length === 0 ? "No file chosen" : `${file.length} Files`} </label>
            </div>
          </div>
        </div>
      </>
    );
  }
  return <Spinner className="primary"></Spinner>;
};

export default UploadImages;
