import React, { useState, useEffect, useRef } from 'react';
import { Card, CardBody, Col, Table, Button, Spinner } from 'reactstrap';
import ReactStars from 'react-stars';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';

import { uploadDealer } from '../../../util/api';

const PageContent = ({ onUpload }) => {
  const [loading, setLoading] = useState(false);
  const csvFileRef = useRef(null);

  const onFileChange = async (e) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('csv', e.target.files[0]);

    uploadDealer(formData)
      .then(({ status, message }) => {
        if (status === 'success') {
          toast.success(`🚀 ${message}`);
        } else {
          toast.error(`⛔ ${message}`);
        }

        onUpload();
      })
      .catch((error) => {
        toast.error('⛔ Failed to upload the CSV');
      })
      .finally(() => setLoading(false));
  };

  const uploadCSV = (event) => {
    csvFileRef.current.click();
  };

  return (
    <>
      <input
        type='file'
        name='csv'
        accept='.csv'
        ref={csvFileRef}
        hidden
        onChange={onFileChange}
      />

      <Button color='primary' onClick={uploadCSV} disabled={loading}>
        {loading && <Spinner color='primary' />}
        {loading === false && 'Upload'}
      </Button>
      <Button
        href={`${process.env.REACT_APP_BACKEND_BASE_URL}/admin/download/dealer`}
        target='_blank'
        rel='noreferer noopener'
        color='primary'
      >
        Download
      </Button>
    </>
  );
};

export default PageContent;
