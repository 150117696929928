import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Input, Button, Row, Label, Spinner } from 'reactstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';

import {
  getVariations,
  getCarMakes,
  getCarModels,
  addNewVariation,
  removeVariation,
} from '../../../util/api';

const VairationContainer = ({ type, make, onSourceChange }) => {
  const [adding, setAdding] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [variations, setVariations] = useState([]);
  const [selectedVariations, setSelectedVariations] = useState([]);
  const [newVariation, setNewVariation] = useState('');
  const [selectedSource, setSelectedSource] = useState('');

  useEffect(() => {
    if (type === 'make') {
      getCarMakes().then((options) => setSourceOptions(options));
    }
  }, []);

  useEffect(() => {
    resetVariations();
  }, [selectedSource]);

  useEffect(() => {
    if (type === 'model' && !!make) {
      getCarModels(make).then((options) => setSourceOptions(options));
    }
  }, [make]);

  const resetVariations = () => {
    if (!selectedSource) return;
    getVariations(type, selectedSource, make).then((variations) =>
      setVariations(variations)
    );
  };

  const handleChange = (e) => {
    const options = e.target.options;
    let value = [];

    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }

    setSelectedVariations(value);
  };

  const handleRemove = () => {
    if (window.confirm('Are you sure to delete variations?')) {
      setRemoving(true);
      removeVariation(type, selectedSource, selectedVariations, make)
        .then((res) => {
          if (res.status === 'success') {
            resetVariations();
            toast.success(`🚀 ${res.message}`);
          } else {
            toast.error(`⛔ ${res.message}`);
          }
        })
        .catch(() => toast.error(`⛔ Failed to remove the variations`))
        .finally(() => setRemoving(false));
    }
  };

  const handleAdd = () => {
    setAdding(true);
    addNewVariation(type, selectedSource, newVariation, make)
      .then((res) => {
        if (res.status === 'success') {
          resetVariations();
          setNewVariation('');
          toast.success(`🚀 ${res.message}`);
        } else {
          toast.error(`⛔ ${res.message}`);
        }
      })
      .catch(() => toast.error(`⛔ Failed to add the variation`))
      .finally(() => setAdding(false));
  };

  const handleSourceChange = ({ value }) => {
    setSelectedSource(value);
    if (onSourceChange) {
      onSourceChange(value);
    }
  };

  return (
    <>
      <Select options={sourceOptions} onChange={handleSourceChange} />
      <Label className='mt-3'>
        Input the make variation here and click add to add a new variation
      </Label>
      <Input
        placeholder='Enter the variation here'
        onChange={(e) => setNewVariation(e.target.value)}
        value={newVariation}
      />
      <Label className='mt-3'>
        Select the variations here and click remove to remove them
      </Label>
      <select multiple className='form-control' onChange={handleChange}>
        {variations.map((v) => (
          <option key={v.key}>{v.value}</option>
        ))}
      </select>
      <Button color='primary' className='mt-1' onClick={handleAdd}>
        {adding && <Spinner color='primary' />}
        {adding === false && 'Add'}
      </Button>
      <Button color='danger' className='mt-1' onClick={handleRemove}>
        Remove
      </Button>
    </>
  );
};

export default VairationContainer;
