import axios from 'axios';

const createAxios = (token) => {
  const config = {
    headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
  };
  const instance = axios.create(config);

  return instance;
};

export const POST = (url, data, token = null) =>
  createAxios(token).post(url, data);

export const GET = (url, params, token = null) =>
  createAxios(token).get(url, { params });

export const DELETE = (url, params, token = null) =>
  createAxios(token).delete(url, { params });

export const login = async (email, password) => {
  let { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/login`,
    {
      email,
      password,
      isAdmin: true,
    }
  );
  return data;
};

export const uploadSpecData = async (formData) => {
  const token = localStorage.getItem('authToken');

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/upload/specs`,
    formData,
    config
  );

  return data;
};

export const getSpecsRecordsCount = async () => {
  const token = localStorage.getItem('authToken');

  const { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/count/specs`,
    {},
    token
  );

  return data;
};

export const uploadMake = async (formData) => {
  const token = localStorage.getItem('authToken');

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/upload/makes`,
    formData,
    config
  );

  return data;
};

export const getMakeRecordsCount = async () => {
  const token = localStorage.getItem('authToken');

  const { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/count/makes`,
    {},
    token
  );

  return data;
};

export const uploadDealer = async (formData) => {
  const token = localStorage.getItem('authToken');

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/upload/dealer`,
    formData,
    config
  );

  return data;
};

export const getDealerRecordsCount = async () => {
  const token = localStorage.getItem('authToken');

  const { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/count/dealers`,
    {},
    token
  );

  return data;
};

export const getAllDealers = async (pageNum = 0, searchQuery = {}) => {
  const token = localStorage.getItem('authToken');

  const { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/dealer`,
    { pageNum: pageNum, perPage: 50, ...searchQuery },
    token
  );

  return data;
};

export const updateDealer = async (id, formData) => {
  return axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/dealer/${id}`,
    formData,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const updateConfig = async (config) => {
  const token = localStorage.getItem('authToken');

  const { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/config`,
    config,
    token
  );

  return data;
};

export const getAdminEmail = async () => {
  const token = localStorage.getItem('authToken');

  const { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/credential`,
    {},
    token
  );

  return data;
};

export const updateAdminCredential = async (credential) => {
  const token = localStorage.getItem('authToken');

  const { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/credential`,
    credential,
    token
  );

  return data;
};

export const getConfig = async () => {
  const token = localStorage.getItem('authToken');

  const { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/extra/config`,
    {},
    token
  );

  return data;
};

export const getPromoCodes = async () => {
  const token = localStorage.getItem('authToken');

  const { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/promo`,
    {},
    token
  );

  return data;
};

export const addNewPromoCode = async ({ code, amount, type }) => {
  const token = localStorage.getItem('authToken');

  const { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/promo`,
    { code, amount, type },
    token
  );

  return data;
};

export const deletePromoCode = async (code) => {
  const token = localStorage.getItem('authToken');

  const { data } = await DELETE(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/promo`,
    { code },
    token
  );

  return data;
};

export const getCarMakes = async (forSelect = true) => {
  let { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/cars/make`
  );
  if (!forSelect) return data.makes;

  let makes = [];
  for (let make of data.makes) {
    makes.push({
      value: make.name,
      label: make.name,
    });
  }

  return makes;
};

export const getCarModels = async (make) => {
  let { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/cars/model`,
    {
      make,
    }
  );

  let models = [];
  for (let model of data.models) {
    if (model.name) {
      models.push({
        value: model.name,
        label: model.name,
      });
    }
  }
  return models;
};

export const getCarVariants = async (model) => {
  let { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/cars/variant`,
    {
      model,
    }
  );

  let variants = [];
  for (let variant of data.variants) {
    if (variant.name) {
      variants.push({
        value: variant.name,
        label: variant.name,
      });
    }
  }
  return variants;
};

export const getVariations = async (type, source, make) => {
  const token = localStorage.getItem('authToken');

  let { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/variation`,
    {
      type,
      source,
      make,
    },
    token
  );

  let variations = [];
  for (let variation of data.data) {
    variations.push({
      key: `variantion-${variation.name}`,
      value: variation.variation,
    });
  }
  return variations;
};

export const addNewVariation = async (type, source, variation, make) => {
  const token = localStorage.getItem('authToken');

  const { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/variation`,
    { type, source, variation, make },
    token
  );

  return data;
};

export const removeVariation = async (type, source, variation, make) => {
  const token = localStorage.getItem('authToken');

  const { data } = await DELETE(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/variation`,
    { type, source, variation: JSON.stringify(variation), make },
    token
  );

  return data;
};

export const adminVerify = async (token) => {
  const { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/validate`,
    {},
    token
  );
  return data;
};

export const submitEdittedCar = async (editCarObj, carId) => {
  const token = localStorage.getItem('authToken');
  const { data } = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/car/edit/${carId}`,
    editCarObj,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${token}`,
      },
    }
  );
  return data;
};

export const getCarDetails = async (carId) => {
  const { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/cars/${carId}`
  );
  return data;
};

export const removeCarImage = async (carId, image) => {
  console.log(image);
  const token = localStorage.getItem('authToken');
  const { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/removeimage/${carId}`,
    image,
    token
  );
  return data;
};

export const optOutDealer = async (baseURL) => {
  const token = localStorage.getItem('authToken');

  const { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/dealer/opt-out`,
    { baseURL },
    token
  );

  return data;
};

export const optInDealer = async (baseURL) => {
  const token = localStorage.getItem('authToken');

  const { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/dealer/opt-in`,
    { baseURL },
    token
  );

  return data;
};
