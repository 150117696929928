import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Input,
  Row,
  Spinner,
} from 'reactstrap';

import { Container, BoxUpload, ImagePreview } from './style';
import { updateDealer } from '../../../util/api';

import FolderIcon from '../../../shared/img/folder_icon_transparent.png';
import CloseIcon from '../../../shared/img/CloseIcon.svg';

export default (props) => {
  const [image, setImage] = useState('');
  const [isUploaded, setIsUploaded] = useState(false);
  const { dealer, isOpen, close } = props;
  const [name, setName] = React.useState(dealer.name);
  const [logo, setLogo] = useState(dealer.logo);
  const [baseURL, setBaseURL] = useState(dealer.baseURL);
  const [postcode, setPostcode] = useState(dealer.postcode);
  const [phone, setPhone] = useState(dealer.phone);
  const [email, setEmail] = useState(dealer.email);
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState(dealer.rating);
  const [address, setAddress] = useState(dealer.address);
  const [id, setId] = useState(dealer._id);
  const [file, setFile] = useState(null);

  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      // setTypeFile(e.target.files[0].type);
      let reader = new FileReader();

      setFile(e.target.files[0]);
      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  useEffect(() => {
    if (dealer) {
      setName(dealer.name);
      setBaseURL(dealer.baseURL);
      setPostcode(dealer.postcode);
      setPhone(dealer.phone);
      setEmail(dealer.email);
      setRating(dealer.rating);
      setAddress(dealer.address);
      setId(dealer._id);
      setLogo(dealer.logo);
    }
  }, [dealer]);

  const saveChanges = () => {
    setLoading(true);
    const data = new FormData();
    data.append('name', name);
    data.append('baseURL', baseURL);
    data.append('postcode', postcode);
    data.append('phone', phone);
    data.append('email', email);
    data.append('rating', rating);
    data.append('address', address);
    data.append('file', file);

    updateDealer(id, data)
      .then((res) => {
        setIsUploaded(false);
        setImage(null);
        setFile(null);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        close(true);
      });
  };
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>Dealer details</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={12}>
            <Container>
              <BoxUpload>
                <div className='image-upload'>
                  {!isUploaded ? (
                    <>
                      <label htmlFor='upload-input'>
                        <img
                          src={logo}
                          draggable={'false'}
                          alt='placeholder'
                          style={{
                            width: 300,
                            height: 300,
                            borderRadius: '15px',
                            marginBottom: '10px',
                          }}
                        />
                        <p style={{ color: '#17a2b8' }}>
                          Click to upload image
                        </p>
                      </label>

                      <input
                        id='upload-input'
                        type='file'
                        accept='.jpg,.jpeg,.gif,.png'
                        onChange={handleImageChange}
                      />
                    </>
                  ) : (
                    <ImagePreview>
                      <img
                        className='close-icon'
                        src={CloseIcon}
                        alt='CloseIcon'
                        onClick={() => {
                          setIsUploaded(false);
                          setImage(null);
                          setFile(null);
                        }}
                      />
                      <img
                        id='uploaded-image'
                        src={image}
                        draggable={false}
                        alt='uploaded-img'
                      />
                    </ImagePreview>
                  )}
                </div>
              </BoxUpload>
            </Container>
          </Col>

          <Col lg={12}>
            <form
              method='post'
              encType='multipart/form-data'
              style={{ marginTop: '10px' }}
            >
              <Input
                name='name'
                type='text'
                value={name}
                placeholder={dealer.name}
                onChange={(e) => setName(e.target.value)}
              />
              <Input
                name='baseURL'
                type='text'
                value={baseURL}
                placeholder='Base Url without http'
                onChange={(e) => setBaseURL(e.target.value)}
                className='mt-2'
              />
              <Input
                name='postcode'
                type='text'
                value={postcode}
                placeholder='Postcode'
                className='mt-2'
                onChange={(e) => setPostcode(e.target.value)}
              />
              <Input
                name='phone'
                type='text'
                value={phone}
                placeholder='Phone'
                onChange={(e) => setPhone(e.target.value)}
              />
              <Input
                name='email'
                type='text'
                value={email}
                placeholder='Email'
                className='mt-2'
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                name='rating'
                type='number'
                value={rating}
                placeholder='Rating'
                className='mt-2'
                onChange={(e) => setRating(e.target.value)}
              />
              <Input
                name='address'
                type='text'
                value={address}
                placeholder='Address'
                className='mt-2'
                onChange={(e) => setAddress(e.target.value)}
              />
            </form>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        {loading ? (
          <Spinner
            color='primary'
            style={{ width: '3rem', height: '3rem', marginRight: '150px' }}
          />
        ) : (
          ''
        )}
        <Button color='primary' onClick={saveChanges} disabled={loading}>
          Save Changes
        </Button>{' '}
        <Button color='secondary' onClick={close}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
