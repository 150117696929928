import React, { PureComponent } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import axios from 'axios';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import { Button, Spinner } from 'reactstrap';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link } from 'react-router-dom';
import CheckBox from '../../../shared/components/form/CheckBox';

import { login } from '../../../util/api';
import { toast } from 'react-toastify';

class LogInForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      showPassword: false,
      email: '',
      password: '',
      isAuthenticated: false,
      message: '',
      loading: false,
    };
  }

  componentDidMount() {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      this.setState({ isAuthenticated: true });
    }
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  onChangeHandler = (e) => {
    let name = e.target.name;
    this.setState({ [name]: e.target.value });
  };

  onSubmitHandler = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    const { email, password } = this.state;

    login(email, password)
      .then((res) => {
        if (res.status === 'success') {
          localStorage.setItem('authToken', res.token);

          this.setState({ isAuthenticated: true });
          toast.success(`🚀 Logged in successfully ...`);
        } else toast.error(`⛔  ${res.message}`, { position: 'top-center' });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { message, showPassword, isAuthenticated } = this.state;
    return (
      <>
        {isAuthenticated ? <Redirect to='/specs' /> : <Redirect to='/' />}

        <form className='form' onSubmit={this.onSubmitHandler}>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Username</span>
            <div className='form__form-group-field'>
              <div className='form__form-group-icon'>
                <AccountOutlineIcon />
              </div>
              <input
                name='email'
                type='email'
                value={this.state.email}
                onChange={this.onChangeHandler}
                placeholder='Email'
                required
              />
            </div>
          </div>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Password</span>
            <div className='form__form-group-field'>
              <div className='form__form-group-icon'>
                <KeyVariantIcon />
              </div>
              <input
                name='password'
                type={showPassword ? 'text' : 'password'}
                placeholder='Password'
                onChange={this.onChangeHandler}
                required
              />
              <button
                className={`form__form-group-button${
                  showPassword ? ' active' : ''
                }`}
                onClick={(e) => this.showPassword(e)}
                type='button'
              >
                <EyeIcon />
              </button>
            </div>
          </div>

          {message ? <h5 style={{ color: 'red' }}>{message}</h5> : ''}
          <Button
            color='primary'
            className='account__btn account__btn--small'
            disabled={this.state.loading}
          >
            {this.state.loading && <Spinner color='primary' />}
            {this.state.loading === false && 'Sign in'}
          </Button>
        </form>
      </>
    );
  }
}

export default LogInForm;
