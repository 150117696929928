import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../LogIn/index';
import SpecsData from '../SpecsData';
import EditCar from '../EditCar';
import Dealers from '../Dealers';
import UserData from '../UserData';
import Standard from '../Standard';
import Config from '../Config';
import PromoCodes from '../PromoCodes';
import Variations from '../Variations';

const wrappedRoutes = () => {
  if (localStorage.getItem('authToken')) {
    return (
      <div>
        <Layout />
        <div className='container__wrap'>
          <Switch>
            <Route path='/specs' component={SpecsData} />
            <Route path='/dealers' component={Dealers} />
            <Route path='/user-data' component={UserData} />
            <Route path='/standard' component={Standard} />
            <Route path='/config' component={Config} />
            <Route path='/promocodes' component={PromoCodes} />
            <Route path='/variations' component={Variations} />
          </Switch>
        </div>
      </div>
    );
  }
  return <Redirect to='/log_in' />;
};

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path='/' component={LogIn} />
        <Route exact path='/log_in' component={LogIn} />
        <Route exact path='/edit-car/:carId' component={EditCar} />
        <Route path='/' component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
