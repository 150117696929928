import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Table,
  Button,
  Input,
  Spinner,
  Form,
  FormGroup,
  Select,
} from 'reactstrap';
import ReactStars from 'react-stars';

import {
  getPromoCodes,
  addNewPromoCode,
  deletePromoCode,
} from '../../../util/api';
import { toast } from 'react-toastify';

const PageContent = () => {
  const [loading, setLoading] = useState(false);
  const [promoCodes, setPromoCodes] = useState([]);
  const [newCode, setNewCode] = useState({
    code: '',
    type: 'Price',
    amount: 0,
  });

  useEffect(() => {
    resetCodesList();
  }, []);

  const resetCodesList = () => {
    getPromoCodes().then((res) => {
      if (res.status === 'success') {
        setPromoCodes(res.data);
      }
    });
  };

  const onAdd = () => {
    setLoading(true);

    addNewPromoCode(newCode)
      .then((res) => {
        if (res.status === 'success') {
          toast.success(`🚀 ${res.message}`);
        } else {
          toast.error(`⛔ ${res.message}`);
        }
        resetCodesList();
      })
      .catch(() => toast.error('⛔ Failed to add a new code'))
      .finally(() => setLoading(false));
  };

  const onRemove = (code) => {
    if (window.confirm(`Are you sure to delete the promocode : ${code}?`)) {
      deletePromoCode(code)
        .then((res) => {
          if (res.status === 'success') {
            toast.success(`🚀 ${res.message}`);
          } else {
            toast.error(`⛔ ${res.message}`);
          }
          resetCodesList();
        })
        .catch(() => toast.error('⛔ Failed to delete a new code'))
        .finally(() => setLoading(false));
    }
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className='card__title'>
            <h5 className='bold-text'>Promo Codes List</h5>
            <h5 className='subhead'>Add or remove a promo code</h5>
          </div>
          {promoCodes.length === 0 && (
            <h5 className='bold-text mb-2'>No promo codes yet</h5>
          )}
          {promoCodes.length > 0 && (
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Code</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {promoCodes.map((v, idx) => (
                  <tr>
                    <th scope='row'>{idx + 1}</th>
                    <td>{v.code}</td>
                    <td>{`${v.amount}${v.type === 'Percent' ? '%' : '£'}`}</td>
                    <td>
                      <Button
                        color='danger'
                        size='sm'
                        className='mb-0'
                        onClick={() => onRemove(v.code)}
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          <Form inline>
            <FormGroup className='mb-2 me-sm-2 mb-sm-0'>
              <Input
                type='text'
                placeholder='Enter the promo code here'
                onChange={(e) =>
                  setNewCode({ ...newCode, code: e.target.value })
                }
                value={newCode.code}
                className='mr-3'
              />
            </FormGroup>
            <FormGroup className='mb-2 me-sm-2 mb-sm-0'>
              <Input
                type='number'
                placeholder='Enter the amount here'
                onChange={(e) =>
                  setNewCode({ ...newCode, amount: e.target.value })
                }
                value={newCode.amount}
                className='mr-3'
              />
            </FormGroup>
            <FormGroup className='mb-2 me-sm-2 mb-sm-0'>
              <Input
                type='select'
                placeholder='Select the promo code type'
                onChange={(e) =>
                  setNewCode({ ...newCode, type: e.target.value })
                }
                value={newCode.type}
              >
                <option>Percent</option>
                <option>Price</option>
              </Input>
            </FormGroup>
          </Form>
          <Button
            color='primary'
            className='mt-1'
            onClick={onAdd}
            disabled={loading}
          >
            {loading && <Spinner color='primary' />}
            {loading === false && 'Add'}
          </Button>
        </CardBody>
      </Card>
    </Col>
  );
};

export default PageContent;
