import React, { useState } from 'react';
import { Card, CardBody, Col, Input, Button, Row, Label } from 'reactstrap';
import Select from 'react-select';

import Variations from './VariationContainer';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const PageContent = () => {
  const [make, setMake] = useState('');
  const handleChange = (source) => {
    setMake(source);
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className='card__title'>
            <h5 className='bold-text'>Make and Model Variations</h5>
            <h5 className='subhead'>Add variations here</h5>
          </div>
          <Row>
            <Col lg={6}>
              <Variations type='make' onSourceChange={handleChange} />
            </Col>
            <Col lg={6}>
              <Variations type='model' make={make} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default PageContent;
