import React, { createRef } from 'react';
import { Card, CardBody, Col, Button, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import { uploadMake, getMakeRecordsCount } from '../../../util/api';

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      totalRecords: 0,
    };
    this.onFileChange = this.onFileChange.bind(this);
    this.hiddenFileInput = createRef();
  }

  componentDidMount() {
    getMakeRecordsCount().then((data) =>
      this.setState({ totalRecords: data.count })
    );
  }

  onFileChange = async (e) => {
    this.setState({ loading: true });

    const formData = new FormData();
    formData.append('csv', e.target.files[0]);

    uploadMake(formData)
      .then(({ status, message }) => {
        if (status === 'success') {
          toast.success(`🚀 ${message}`);
        } else {
          toast.error(`⛔ ${message}`);
        }
      })
      .catch((error) => {
        toast.error('⛔ Failed to upload the CSV');
      })
      .finally(() => this.setState({ loading: false }));
  };

  uploadCSV = (event) => {
    this.hiddenFileInput.current.click();
  };

  render() {
    return (
      <Col md={12}>
        <Card>
          <CardBody>
            <div className='card__title'>
              <h5 className='bold-text'>Upload/Download</h5>
              <h5 className='subhead'>Upload/Download a CSV file</h5>
            </div>

            <p className='mb-3'>
              Currently, we have total {this.state.totalRecords} car makes
            </p>
            <input
              type='file'
              name='csv'
              accept='.csv'
              ref={this.hiddenFileInput}
              hidden
              onChange={this.onFileChange}
            />

            <Button
              color='primary'
              onClick={this.uploadCSV}
              disabled={this.state.loading}
            >
              {this.state.loading && <Spinner color='primary' />}
              {this.state.loading === false && 'Upload'}
            </Button>
            <Button
              href={`${process.env.REACT_APP_BACKEND_BASE_URL}/admin/download/makes`}
              color='primary'
              target='_blank'
              rel='noreferer noopener'
            >
              Download
            </Button>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default Content;
