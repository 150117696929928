import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import PageContent from "./components/Content";
import { adminVerify } from "../../util/api";
import { Redirect, useLocation } from "react-router-dom";

/* on the public site, admins can click 'Edit this car' button. Once it's clicked it will be redirected to this page
The url looks like this: http://admin.carmart.com/edit-car/:id?token=1111111111 (check the route /src/containers/App/Router.jsx line 44)

The admins can edit the car details here.
1. When it's redirected to here, you have to validate the token. Make sure only admins can access this page and edit car here.
   On the back end, it has an endpoint 'admin/validte' to check it. (You can edit this endpoint if something is wrong)
   When it's not admin, it should be redirected to login page. Otherwise, it should show the form which enables the admin to edit the car

2. When it's admin, it should show the form to edit the car. It should be able to edit the all fields in car.model.js in back end codebase.
   The fields are 
   mileage, number input
   make: select (you can get select options from the back end - it shouldn't contain 'Any' )
   model: same as make, select 
   variant: same as make, select,
   price: number input,
   color: select (options are from /src/constants/car-specs.js on front end codebase),
   transmission: select (options are from /src/constants/car-specs.js on front end codebase: gearBox),
   year: number input,
   doorCnt: number input,
   bodyType: select (options are from /src/constants/car-specs.js on front end codebase),
   engineSize: number input ,
   fuelType: select (options are from /src/constants/car-specs.js on front end codebase),
   headline: text input,
   description: text input (textarea),
   postcode: text input, (on the back end, while saving the postcode, call formatPostcode function to format the postcode in standard format, formatPostcode function alredy exists)
             while saving postcode, you can get the latitude and longitude from postcode, save them as well. (check cars/add-new route on back end)
   sellerType: select (options are from /src/constants/car-specs.js on front end codebase : advertType),

   The endpoint to edit car is /admin/car/edit, which you should add as well (for now, only mockup exists, check back end)

3. While editing the car, it should enable the admin to uploads the images.
   Currently all images are the links. So First of all, it should show all existing images with 'remove' button, which will remove the image link.
   Admin can upload the images from the local and it should be saved in the absolute url including http.
   It should be saved /public/cars on back end. If you are not sure, I will help you.

I want to seperate the work by 2 parts. 1st part is just edit the car details and 2nd part is to edit images.
But I don't care it so do what you prefer.
*/

const EditCar = (props) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  useEffect(() => {
    adminVeriication(token);
  }, []);

  useEffect(() => {}, [isAdmin]);

  const adminVeriication = (token) => {
    adminVerify(token)
      .then((res) => {
        if (res.status === "success") {
          setIsAdmin(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  if (loading) {
    return <Spinner color="primary" />;
  }

  return !isAdmin ? (
    <Redirect to="/log_in" />
  ) : (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Car Listing Data</h3>
        </Col>
      </Row>
      <Row>
        <PageContent match={props.match} />
      </Row>
    </Container>
  );
};

export default EditCar;
