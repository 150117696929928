import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Input, Label, Button, FormFeedback } from "reactstrap";
import { Field, Formik } from "formik";
import Select from "react-select";
import { getCarMakes, getCarModels, getCarVariants, submitEdittedCar, getCarDetails } from "../../../util/api";
import carSpecs from "../../../constants/car-specs";
import { getValidationSchema, initials } from "./formikConstants";
import { toast } from "react-toastify";
import UploadImages from "./uploadImages";

const Content = (props) => {
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [variants, setVariants] = useState([]);
  const [carDetails, setCarDetails] = useState({});
  const carId = props.match.params.carId;

  useEffect(() => {
    fetchMakes();
    fetchCarDetails();
  }, []);

  const fetchCarDetails = () => {
    getCarDetails(carId).then((res) => {
      setCarDetails(res);
      if (![null, "", undefined].includes(res.make)) fetchCarModels(res.make);
      if (![null, "", undefined].includes(res.model)) fetchCarVariants(res.model);
    });
  };

  const fetchMakes = () => {
    getCarMakes().then((options) => setMakes(options));
  };

  const submitEditCar = (values, setSubmitting, resetForm) => {
    setSubmitting(true);
    const editCarObj = new FormData();
    Object.entries(values).map((value) => {
      if (typeof value[1] === "object") {
        if (value[0] === "images") {
          for (const key of Object.keys(values.images)) {
            editCarObj.append("images", values.images[key]);
          }
        } else editCarObj.append(value[0], value[1].value);
      } else editCarObj.append(value[0], value[1]);
    });

    submitEdittedCar(editCarObj, carId)
      .then((res) => {
        if (res.status === "success") {
          toast.success(`🚀 ${res.message}`);
        } else {
          toast.error(`⛔ ${res.message}`);
        }
        setSubmitting(false);
      })
      .catch((error) => {
        toast.error("⛔ Failed to upload the data");
      });
    resetForm();
  };

  const fetchCarModels = (value) => {
    getCarModels(value).then((options) => setModels(options));
  };
  const fetchCarVariants = (value) => {
    getCarVariants(value).then((options) => setVariants(options));
  };

  const handleChange = (value, name, setFieldValue) => {
    switch (name) {
      case "make":
        fetchCarModels(value.value);
        break;
      case "model":
        fetchCarVariants(value.value);
        break;
      default:
        break;
    }
    setFieldValue(name, value);
  };
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Example title</h5>
            <h5 className="subhead">Example subhead</h5>
          </div>
          <Formik
            initialValues={initials(carDetails)}
            enableReinitialize
            validationSchema={getValidationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              submitEditCar(values, setSubmitting, resetForm);
            }}
          >
            {({ handleSubmit, setFieldValue, values, errors, touched, setFieldTouched, isSubmitting }) => (
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <Label>Mileage</Label>
                        <Input type="text" name="mileage" value={values.mileage} tag={Field} invalid={errors.mileage && touched.mileage} />
                        <FormFeedback>{errors.mileage}</FormFeedback>
                      </div>
                      <div className="col-md-6">
                        <Label>Make</Label>
                        <Select
                          name="make"
                          id="make"
                          options={makes}
                          value={values.make}
                          onChange={(value) => handleChange(value, "make", setFieldValue)}
                          touched={touched.make}
                          onBlur={() => setFieldTouched("make", true)}
                        />
                        {errors.make && touched.make && <div style={{ color: "red" }}>{errors.make}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <Label>Model</Label>
                        <Select
                          name="model"
                          id="model"
                          value={values.model}
                          options={models}
                          onChange={(value) => handleChange(value, "model", setFieldValue)}
                          onBlur={() => setFieldTouched("model", true)}
                        />
                        {errors.model && touched.model && <div style={{ color: "red" }}>{errors.model}</div>}
                      </div>
                      <div className="col-md-6">
                        <Label>Variant</Label>
                        <Select
                          name="variant"
                          id="variant"
                          value={values.variant}
                          options={variants}
                          onChange={(value) => handleChange(value, "variant", setFieldValue)}
                          onBlur={() => setFieldTouched("variant", true)}
                        />
                        {errors.variant && touched.variant && <div style={{ color: "red" }}>{errors.variant}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <Label>Price</Label>
                        <Input type="text" name="price" value={values.price} tag={Field} invalid={errors.price && touched.price} />
                        <FormFeedback>{errors.price}</FormFeedback>
                      </div>
                      <div className="col-md-6">
                        <Label>Color</Label>
                        <Select
                          name="variant"
                          id="variant"
                          value={values.color}
                          options={carSpecs.color}
                          onChange={(value) => handleChange(value, "color", setFieldValue)}
                          onBlur={() => setFieldTouched("color", true)}
                        />
                        {errors.color && touched.color && <div style={{ color: "red" }}>{errors.color}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <Label>Year</Label>
                        <Input type="text" name="year" value={values.year} tag={Field} invalid={errors.year && touched.year} />
                        <FormFeedback>{errors.year}</FormFeedback>
                      </div>
                      <div className="col-md-6">
                        <Label>Transmission</Label>
                        <Select
                          name="transmission"
                          id="transmission"
                          value={values.transmission}
                          options={carSpecs.gearBox}
                          onChange={(value) => handleChange(value, "transmission", setFieldValue)}
                          onBlur={() => setFieldTouched("transmission", true)}
                        />
                        {errors.transmission && touched.transmission && <div style={{ color: "red" }}>{errors.transmission}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <Label>Door Count</Label>
                        <Input type="text" name="doorCnt" value={values.doorCnt} tag={Field} invalid={errors.doorCnt && touched.doorCnt} />
                        <FormFeedback>{errors.doorCnt}</FormFeedback>
                      </div>
                      <div className="col-md-6">
                        <Label>Body Type</Label>
                        <Select
                          name="bodyType"
                          id="bodyType"
                          value={values.bodyType}
                          options={carSpecs.bodyType}
                          onChange={(value) => handleChange(value, "bodyType", setFieldValue)}
                          onBlur={() => setFieldTouched("bodyType", true)}
                        />
                        {errors.bodyType && touched.bodyType && <div style={{ color: "red" }}>{errors.bodyType}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <Label>Engine Size</Label>
                        <Input type="text" name="engineSize" value={values.engineSize} tag={Field} invalid={errors.engineSize && touched.engineSize} />
                        <FormFeedback>{errors.engineSize}</FormFeedback>
                      </div>
                      <div className="col-md-6">
                        <Label>Fuel Type</Label>
                        <Select
                          name="fuelType"
                          id="fuelType"
                          value={values.fuelType}
                          options={carSpecs.fuelType}
                          onChange={(value) => handleChange(value, "fuelType", setFieldValue)}
                          onBlur={() => setFieldTouched("fuelType", true)}
                        />
                        {errors.fuelType && touched.fuelType && <div style={{ color: "red" }}>{errors.fuelType}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <Label>HeadLine</Label>
                        <Input type="text" name="headline" value={values.headline} tag={Field} invalid={errors.headline && touched.headline} />
                        <FormFeedback>{errors.headline}</FormFeedback>
                      </div>
                      <div className="col-md-6">
                        <Label>PostCode</Label>
                        <Input type="text" name="postcode" value={values.postcode} tag={Field} invalid={errors.postcode && touched.postcode} />
                        <FormFeedback>{errors.postcode}</FormFeedback>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <Label>Seller Type</Label>
                        <Select
                          name="sellerType"
                          id="sellerType"
                          value={values.sellerType}
                          options={carSpecs.advertType}
                          onChange={(value) => handleChange(value, "sellerType", setFieldValue)}
                          onBlur={() => setFieldTouched("sellerType", true)}
                        />{" "}
                        {errors.sellerType && touched.sellerType && <div style={{ color: "red" }}>{errors.sellerType}</div>}
                      </div>
                      <div className="col-md-6">
                        <Label>Description</Label>
                        <Field name="description" render={({ field }) => <Input type="textarea" {...field} invalid={errors.description && touched.description} />} />
                        <FormFeedback>{errors.description}</FormFeedback>
                      </div>
                    </div>
                    <div className="form-group" style={{ marginTop: "1rem" }}>
                      <UploadImages name="images" setFieldValue={setFieldValue} images={values.images} carDetails={carDetails} carId={carId} fetchCarDetails={fetchCarDetails} />
                    </div>
                  </div>
                  <div className="form-group">
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Col>
  );
};
export default Content;
