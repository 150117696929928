import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Input,
  Button,
  FormGroup,
  Label,
  Spinner,
  FormFeedback,
} from 'reactstrap';
import { toast } from 'react-toastify';

import {
  getConfig,
  updateConfig,
  getAdminEmail,
  updateAdminCredential,
} from '../../../util/api';

const PageContent = () => {
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({
    price: 5,
  });
  const [adminLoading, setAdminLoading] = useState(false);
  const [credential, setCredential] = useState({
    email: '',
    password: '',
    cfrmPwd: '',
  });
  const [formErr, setFormErr] = useState({
    cfrmPwd: false,
  });

  useEffect(() => {
    getConfig().then((res) => {
      if (res.status === 'success') setConfig({ price: res.price });
    });
    getAdminEmail().then((res) => {
      if (res.status === 'success')
        setCredential({ ...credential, email: res.email });
    });
  }, []);

  const updateSiteConfig = () => {
    setLoading(true);
    updateConfig(config)
      .then((res) => {
        if (res.status === 'success') {
          toast.success(`🚀 ${res.message}`);
        } else {
          toast.error(`⛔ ${res.message}`);
        }
      })
      .catch(() => toast.error('⛔ Failed to update, try again'))
      .finally(() => setLoading(false));
  };

  const updateCredential = () => {
    if (credential.password !== credential.cfrmPwd) {
      setFormErr({ cfrmPwd: true });
      return;
    }
    setAdminLoading(true);
    updateAdminCredential(credential)
      .then((res) => {
        console.log(res);
        if (res.status === 'success') {
          toast.success(`🚀 ${res.message}`);
        } else {
          toast.error(`⛔ ${res.message}`);
        }
      })
      .catch(() => toast.error('⛔ Failed to update, try again'))
      .finally(() => setAdminLoading(false));
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className='card__title'>
            <h5 className='bold-text'>Carmart Configuration</h5>
            <h5 className='subhead'>Edit the configuration</h5>
          </div>
          <FormGroup>
            <Label for='exampleEmail'>Advertising Price</Label>
            <Input
              type='number'
              placeholder='Enter the price here'
              onChange={(e) =>
                setConfig({ ...config, price: parseInt(e.target.value) })
              }
              value={config.price}
            />
          </FormGroup>

          <Button
            color='primary'
            className='mt-2'
            onClick={updateSiteConfig}
            disabled={loading}
          >
            {loading && <Spinner color='primary' />}
            {loading === false && 'Update'}
          </Button>

          <div className='card__title'>
            <h5 className='bold-text'>Admin Credential</h5>
            <h5 className='subhead'>Edit admin credential</h5>
          </div>
          <FormGroup>
            <Label>Email</Label>
            <Input
              type='email'
              placeholder='Enter the email here'
              onChange={(e) =>
                setCredential({ ...credential, email: e.target.value })
              }
              value={credential.email}
            />
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input
              type='password'
              placeholder='Enter the password here'
              onChange={(e) =>
                setCredential({ ...credential, password: e.target.value })
              }
              value={credential.password}
            />
          </FormGroup>
          <FormGroup>
            <Label>Confirm Password</Label>
            <Input
              type='password'
              placeholder='Confirm the password'
              onChange={(e) =>
                setCredential({ ...credential, cfrmPwd: e.target.value })
              }
              value={credential.cfrmPwd}
              invalid={formErr.cfrmPwd}
            />
            <FormFeedback>The password doesn't match</FormFeedback>
          </FormGroup>
          <Button
            color='primary'
            className='mt-2'
            onClick={updateCredential}
            disabled={adminLoading}
          >
            {adminLoading && <Spinner color='primary' />}
            {adminLoading === false && 'Update'}
          </Button>
        </CardBody>
      </Card>
    </Col>
  );
};

export default PageContent;
