import * as Yup from "yup";

export const getValidationSchema = () =>
  Yup.object({
    mileage: Yup.string()
      .required("Required")
      .matches(/^[0-9]*$/, "Must be number and positive"),
    price: Yup.string()
      .required("Required")
      .matches(/^[0-9]*$/, "Must be number and positive"),
    year: Yup.string()
      .required("Required")
      .matches(/^[0-9]*$/, "Must be number and positive"),
    doorCnt: Yup.string()
      .required("Required")
      .matches(/^[0-9]*$/, "Must be number and positive"),
    engineSize: Yup.string()
      .required("Required")
      .matches(/^[0-9]*$/, "Must be number and positive"),
    make: Yup.object().required("Required"),
    model: Yup.object().required("Required"),
    variant: Yup.object().required("Required"),
    fuelType: Yup.object().required("Required"),
    color: Yup.object().required("Required"),
    sellerType: Yup.object().required("Required"),
    transmission: Yup.object().required("Required"),
    bodyType: Yup.object().required("Required"),
    postcode: Yup.string().required("Required"),
    headline: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });

export const initials = (carDetails) => {
  return {
    mileage: carDetails && carDetails.mileage,
    make: carDetails && {
      label: [null, "", undefined].includes(carDetails.make) || carDetails.make.toLowerCase() === "undefined" ? "none" : carDetails.make,
      value: [null, "", undefined].includes(carDetails.make) || carDetails.make.toLowerCase() === "undefined" ? "" : carDetails.make,
    },
    model: carDetails && {
      label: [null, "", undefined].includes(carDetails.model) || carDetails.model.toLowerCase() === "undefined" ? "none" : carDetails.model,
      value: [null, "", undefined].includes(carDetails.model) || carDetails.model.toLowerCase() === "undefined" ? "" : carDetails.model,
    },
    variant: carDetails && {
      label: [null, "", undefined].includes(carDetails.variant) || carDetails.variant.toLowerCase() === "undefined" ? "none" : carDetails.variant,
      value: [null, "", undefined].includes(carDetails.variant) || carDetails.variant.toLowerCase() === "undefined" ? "" : carDetails.variant,
    },
    price: carDetails && carDetails.price,
    color: carDetails && { label: carDetails.color, value: carDetails.color },
    transmission: carDetails && { label: carDetails.transmission, value: carDetails.transmission },
    year: carDetails && carDetails.year,
    doorCnt: carDetails && carDetails.doorCnt,
    bodyType: carDetails && { label: carDetails.bodyType, value: carDetails.bodyType },
    engineSize: carDetails && carDetails.engineSize,
    fuelType: carDetails && { label: carDetails.fuelType, value: carDetails.fuelType },
    headline: carDetails && carDetails.headline,
    description: carDetails && carDetails.description,
    postcode: carDetails && carDetails.postcode,
    sellerType: carDetails && { label: carDetails.sellerType, value: carDetails.sellerType },
    images: "",
  };
};
