import React from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';

const PageContent = () => (
  <Col md={12}>
    <Card>
      <CardBody>
        <div className='card__title'>
          <h5 className='bold-text'>User Data</h5>
          <h5 className='subhead'>Download the list of Carmart users</h5>
        </div>
        <Button
          color='primary'
          href={`${process.env.REACT_APP_BACKEND_BASE_URL}/admin/download/user-data`}
          target='_blank'
          rel='noreferer noopener'
        >
          Download
        </Button>
      </CardBody>
    </Card>
  </Col>
);

export default PageContent;
