import React, { useState, useEffect, useRef } from 'react';
import { Card, CardBody, Col, Table, Button, Spinner, Input } from 'reactstrap';
import ReactStars from 'react-stars';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';

import DetailModal from './DetailModal';
import CSVHeader from './CSVHeader';
import {
  getAllDealers,
  uploadDealer,
  optOutDealer,
  optInDealer,
  getDealerRecordsCount,
} from '../../../util/api';

const PageContent = () => {
  const [showModal, setShowModal] = useState(false);
  const [dealer, setDealer] = useState({ dealer: {} });
  const [data, setData] = useState({ dealers: [] });
  const [pageNum, setPageNum] = useState(0);
  const [searchQuery, setSearchQuery] = useState({
    name: '',
    baseURL: '',
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const csvFileRef = useRef(null);

  const resetData = (pageNum = 0, searchQuery = {}) => {
    getAllDealers(pageNum, searchQuery).then((data) => setData(data));
  };

  useEffect(() => {
    getDealerRecordsCount().then((data) => setTotalRecords(data.count));
  }, []);
  useEffect(() => {
    resetData(pageNum, searchQuery);
  }, [pageNum]);

  const showDetails = (item) => {
    setDealer(item);
    setShowModal(true);
  };

  const handlePageClick = async ({ selected }) => {
    setPageNum(selected);
  };

  const onCloseModal = (isChanged = false) => {
    setShowModal(false);

    if (!isChanged) return;
    getAllDealers(pageNum, searchQuery).then((data) => setData(data));
  };

  const hideCarsOfDealer = (item, idx) => {
    optOutDealer(item.baseURL)
      .then(({ status, message }) => {
        if (status === 'success') {
          toast.success(`🚀 ${message}`);
        } else {
          toast.error(`⛔ ${message}`);
        }

        const temp = { ...data };
        temp.dealers[idx].isOptOut = true;
        setData(temp);
      })
      .catch((error) => {
        toast.error('⛔ Something went wrong');
      });
  };

  const showCarsOfDealer = (item, idx) => {
    optInDealer(item.baseURL)
      .then(({ status, message }) => {
        if (status === 'success') {
          toast.success(`🚀 ${message}`);
        } else {
          toast.error(`⛔ ${message}`);
        }

        const temp = { ...data };
        temp.dealers[idx].isOptOut = false;
        setData(temp);
      })
      .catch((error) => {
        toast.error('⛔ Something went wrong');
      });
  };

  const searchDealer = () => {
    setPageNum(0);
    resetData(0, searchQuery);
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className='card__title'>
            <h5 className='bold-text'>Dealers List</h5>
            <h5 className='subhead'>Edit and remove the dealer</h5>
          </div>

          <p className='mb-3'>
            Currently, we have total {totalRecords} car makes
          </p>

          <CSVHeader onUpload={() => resetData(0)} />

          <div style={styles.searchForm}>
            <Input
              value={searchQuery.name}
              onChange={(e) =>
                setSearchQuery({ ...searchQuery, name: e.target.value })
              }
              placeholder='Dealer name'
              style={styles.searchInput}
            />
            <Input
              value={searchQuery.baseURL}
              onChange={(e) =>
                setSearchQuery({ ...searchQuery, baseURL: e.target.value })
              }
              placeholder='Dealer base url without http(s)'
              style={styles.searchInput}
            />

            <Button color='primary' onClick={searchDealer}>
              Search
            </Button>
          </div>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Logo</th>
                <th>Name</th>
                <th>Base URL</th>
                <th>Postcode</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Rating</th>
                <th>Details</th>
                <th>Opt in/out</th>
              </tr>
            </thead>
            <tbody>
              {data.dealers.map((item, dealerIdx) => (
                <tr key={`dealer-${dealerIdx}`}>
                  <th scope='row'>{dealerIdx + 1 + pageNum * 50}</th>
                  <td>
                    <img
                      src={item.logo}
                      alt='Logo'
                      style={{
                        height: '40px',
                        width: '40px',
                        borderRadius: '4px',
                      }}
                    />
                  </td>
                  <td>{item.name}</td>
                  <td>{item.baseURL}</td>
                  <td>{item.postcode}</td>
                  <td>{item.phone}</td>
                  <td>{item.email}</td>
                  <td>
                    <ReactStars
                      value={item.rating}
                      edit={false}
                      size={24}
                      color2={'#ffd700'}
                    />
                  </td>
                  <td>
                    <Button
                      color='info'
                      size='sm'
                      onClick={() => showDetails(item)}
                    >
                      Details
                    </Button>
                  </td>
                  <td>
                    {!item.isOptOut && (
                      <Button
                        color='danger'
                        size='sm'
                        onClick={() => hideCarsOfDealer(item, dealerIdx)}
                      >
                        Opt out
                      </Button>
                    )}
                    {item.isOptOut && (
                      <Button
                        color='danger'
                        size='sm'
                        onClick={() => showCarsOfDealer(item, dealerIdx)}
                      >
                        Opt in
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={data.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </CardBody>
      </Card>

      <DetailModal isOpen={showModal} dealer={dealer} close={onCloseModal} />
    </Col>
  );
};

const styles = {
  searchForm: {
    display: 'flex',
  },
  searchInput: {
    marginRight: 30,
    maxWidth: 350,
  },
};
export default PageContent;
