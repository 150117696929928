import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  logOut = () => {
    localStorage.removeItem('authToken');
  };

  render() {
    return (
      <div className='sidebar__content'>
        <ul className='sidebar__block'>
          <SidebarLink
            icon='database'
            title='Spec Data'
            route='/specs'
            onClick={this.hideSidebar}
          />
          <SidebarLink
            icon='phone-handset'
            title='Dealers'
            route='/dealers'
            onClick={this.hideSidebar}
          />
          <SidebarLink
            icon='heart'
            title='User Data'
            route='/user-data'
            onClick={this.hideSidebar}
          />
          <SidebarLink
            icon='book'
            title='Standard Makes (Model)'
            route='/standard'
            onClick={this.hideSidebar}
          />
          <SidebarLink
            icon='store'
            title='Configuration'
            route='/config'
            onClick={this.hideSidebar}
          />
          <SidebarLink
            icon='gift'
            title='Promo Codes'
            route='/promocodes'
            onClick={this.hideSidebar}
          />
          <SidebarLink
            icon='diamond'
            title='Variations'
            route='/variations'
            onClick={this.hideSidebar}
          />
        </ul>
        <ul className='sidebar__block'>
          <SidebarLink
            title='Log Out'
            icon='exit'
            route='/log_in'
            onClick={this.logOut}
          />
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
